<template>
  <div>
    <MainLayout>
      <template #header>
        <AppBar @back="$emit('back')">
          <template #right-item>
            <slot name="right-item"></slot>
          </template>
        </AppBar>
      </template>
      <template #body>
        <v-main>
          <v-container class="content-wrap" fluid>
            <slot name="body"></slot>
          </v-container>
        </v-main>
      </template>
    </MainLayout>
  </div>
</template>
<script>
import MainLayout from '@/layouts/default/Index.vue';
import AppBar from './AppBar';
export default {
  components: {
    AppBar,
    MainLayout,
  },
}
</script>
<style lang="scss" scoped>
@mixin for-sm {
  @media (max-width: 599px) {
    @content;
  }
}

.v-main {
  background-color: var(--primary-color);
  min-height: 100vh;
}
.content-wrap {
  padding: 12px 20px;
  @include for-sm {
    padding: 8px;
  }
}
</style>