<template>
  <div>
    <slot name="header">
      <default-bar />
    </slot>

    <slot name="drawer">
      <!-- <default-drawer /> -->
    </slot>

    <slot name="body">
      <default-view />
    </slot>

    <slot name="footer">
      <default-footer />
    </slot>

    <!-- <WelcomeModal v-if="modalShow" v-model="modalShow" /> -->
    <!-- <default-settings /> -->
  </div>
</template>

<script>
// import WelcomeModal from './WelcomeModal';
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'DefaultLayout',

  components: {
    // WelcomeModal,
    DefaultBar: () =>
      import(
        /* webpackChunkName: "default-app-bar" */
        './AppBar'
      ),
    // DefaultDrawer: () =>
    //   import(
    //     /* webpackChunkName: "default-drawer" */
    //     './Drawer'
    //   ),
    DefaultFooter: () =>
      import(
        /* webpackChunkName: "default-footer" */
        './Footer'
      ),
    // DefaultSettings: () =>
    //   import(
    //     /* webpackChunkName: "default-settings" */
    //     './Settings'
    //   ),
    DefaultView: () =>
      import(
        /* webpackChunkName: "default-view" */
        './View'
      ),
  },
  computed: {
    ...mapState({
      loginModalShow: state => state.user.loginModalShow,
    }),
    modalShow: {
      get() {
        return this.loginModalShow;
      },
      set(value) {
        this.SET_LOGIN_MODAL_SHOW(value);
      },
    },
  },
  methods: {
    ...mapMutations(['SET_LOGIN_MODAL_SHOW']),
  },
};
</script>
