<template>
  <MenuLayout @back="back">
    <template #body>
      <router-view v-if="isReady"></router-view>
    </template>
  </MenuLayout>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import MenuLayout from '@/layouts/menu';

export default {
  components: {
    MenuLayout,
  },
  data() {
    return {
      isReady: false,
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
    }),
  },
  async created() {
    let promises = [];
    if (this.isUndefinedNullOrEmpty(this.menuList)) promises.push(this.fetchMenuList());
    if (this.isUndefinedNullOrEmpty(this.menuItemList)) promises.push(this.fetchMenuItemList());
    if (this.isUndefinedNullOrEmpty(this.menuItemOptList)) promises.push(this.fetchMenuItemOptList());
    if (this.isUndefinedNullOrEmpty(this.menuItemOptGrpList)) promises.push(this.fetchMenuItemOptGrpList());
    if (this.isUndefinedNullOrEmpty(this.menuItemOptGrpSubList)) promises.push(this.fetchMenuItemOptGrpSubList());

    if (promises.length > 0) {
      try {
        await Promise.all(promises);
        let expiry = new Date();
        expiry.setDate(expiry.getDate() + 1);
        expiry.setHours(0, 0, 0, 0);
        this.SET_DATA_EXP(expiry.getTime()); // Menu api data expiry
        this.isReady = true;
      } catch (error) {
        console.error('error', error);
      }
    } else {
      this.isReady = true;
    }
  },
  methods: {
    ...mapMutations(['SET_DATA_EXP']),
    ...mapActions([
      'fetchMenuList',
      'fetchMenuItemList',
      'fetchMenuItemOptList',
      'fetchMenuItemOptGrpList',
      'fetchMenuItemOptGrpSubList',
    ]),
    back() {
      const currentRoute = this.$router.history.current;
      if (currentRoute.name == 'MenuSetItem' && currentRoute.query.step == 1) {
        this.navigateReplace('MenuAll');
      } else {
        this.$router.go(-1);
      }
    },
  }
}
</script>