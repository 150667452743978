<template>
  <v-app-bar
    id="menu-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="var(--primary-black-color)"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    :height="barHeight"
  >
    <div class="d-flex justify-space-between align-center flex-grow-1 w-100 h-100">
      <div v-if="showBackBtn" class="left-wrap">
        <v-icon @click="$emit('back')" color="var(--primary-color)" size="30">mdi-arrow-left</v-icon>
      </div>
      <div v-else class="d-flex left-wrap">
        <img
          class="logo cursor-pointer"
          src="@/assets/logo-yellow.svg"
          @click="navigatePush('Main', null, { storeId: $route.query.storeId })"
        />
      </div>
      <div class="center-wrap flex-grow-1 text-center">
        <div class="bar-title">{{ drawerTitle }}</div>
      </div>
      <slot name="right-item">
        <div class="right-wrap d-flex align-center justify-end">
          <v-icon
            v-if="isMenuAllPage"
            class="mr-2"
            @click="navigatePush('OrderHistoryList')"
            color="var(--primary-color)"
            size="38"
            >mdi-history</v-icon
          >
          <v-badge color="green" :content="getCartItemsCount" overlap offset-y="15" :value="displayBadge">
            <img
              @click="navigatePush('Cart')"
              class="icon cursor-pointer mr-2"
              :src="require('@/assets/bar/cart-orange.svg')"
              alt="icon"
            />
          </v-badge>
        </div>
      </slot>
    </div>
    <v-spacer />

    <!-- <default-search class="hidden-sm-and-down" /> -->

    <!-- <default-go-home /> -->

    <!-- <default-notifications /> -->

    <!-- <default-account /> -->
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'MenuAppBar',
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      drawerTitle: state => state.app.drawerTitle,
    }),
    ...mapGetters(['getCartItemsCount']),
    isMenuAllPage() {
      return this.$route.name == 'MenuAll' && !this.$route.params.menuId ? true : false;
    },
    barHeight() {
      return this.isMobileView ? 65 : 75;
    },
    displayBadge() {
      return !this.isUndefinedNullOrEmpty(this.getCartItemsCount) && this.getCartItemsCount > 0 ? true : false;
    },
    showBackBtn() {
      const params = this.$route.params;

      const condition1 = this.$route.name == 'MenuAll' && this.isUndefinedNullOrEmpty(params.menuId);
      return condition1 ? false : true;
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin for-sm {
  @media (max-width: 599px) {
    @content;
  }
}

#menu-app-bar::v-deep {
  box-shadow: none;

  .v-toolbar__content {
    padding: 15px 20px 15px;
    @include for-sm {
      padding: 10px 20px 10px;
    }
  }

  .header-text {
    color: var(--primary-color);
    font-size: 1.75rem;
    font-weight: 600;
  }

  .left-wrap {
    max-width: 100px;
    text-align: left;
    width: 100%;
  }

  .bar-title {
    color: var(--primary-color);
    font-size: 1.75rem;
    font-weight: 500;
  }

  .right-wrap {
    max-width: 100px;
    text-align: right;
    width: 100%;
  }
}
.icon {
  width: 35px;
}
.logo {
  max-width: 55px;
  width: 100%;
}
</style>
